@import '../../../css/global';

.button {
  display: block;
  appearance: none;
  width: percentage(calc(210 / 375));
  max-width: 210px;
  margin: 18px auto 0;
  background: #fff;
  // border: 1px solid #000;
  border-radius: 50px;

  line-height: 1;
  font-weight: bold;
  letter-spacing: 3%;
  font-weight: bold;
  // text-transform: uppercase;
  color: #000;

  @include pxToClampMobile('padding', auto, 13px, auto);
  @include pxToClampMobile('margin-top', auto, 18px, auto);
  @include pxToClampMobile('font-size', auto, 15px, auto);

  &.outline {
    background: none;
    color: #fff;
  }
}
