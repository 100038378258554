@import '../../../css/global';

.wrap {
  padding: 0 0 80px;
}

.brandWrap {
  // @include pxToClampMobile('margin-left', auto, 70px, auto);
}

.brand {
  margin: 0 auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @include pxToClampMobile('width', auto, 245px * 0.9, auto);
  @include pxToClampMobile('height', auto, 100px * 0.9, auto);

  @include hidefont;
}

.prefix {
  margin: 20px auto 0;
  @include pxToClampMobile('font-size', auto, 15px, auto);
  color: #fff;
  letter-spacing: 3%;;

}

.title {
  margin: 20px auto 50px;

  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  color: #fff;
  width: 80%;

  @include pxToClampMobile('font-size', auto, 56px, auto);
}
