@import '../../../css/global';

.wrap {
  @include pxToClampMobile('padding-top', auto, 20px, auto);
  @include pxToClampMobile('padding-bottom', auto, 110px, auto);
}

.title {
  width: percentage(calc(300 / 375));
  max-width: 300px;
  margin: 0 auto;

  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  color: #fff;

  @include pxToClampMobile('font-size', auto, 40px, auto);
}

.body {
  color: #fff;
  letter-spacing: 3%;;
  @include pxToClampMobile('padding-top', auto, 30px, auto);
  @include pxToClampMobile('font-size', auto, 24px, auto);
}
