html,
body {
  font-family: Samsung, Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  line-height: 0.9;
  font-weight: normal;
  text-align: center;
}

html {
  font-size: 62.5%;
}

.theme-login {
  color: var(--color-font-primary);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 1em 0;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5em 0 1em;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

ul,
ol {
  margin-left: 30px;
}

a {
  color: inherit;
}

strong {
  font-weight: 700;
}
