@import '../../../css/global';

.wrap {
  width: percentage(calc(320 / 375));
  max-width: 320px;
  margin: 0 auto;
  // text-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
}

.prefix {
  font-weight: 600;
  letter-spacing: 3%;;
  text-transform: uppercase;


  @include pxToClampMobile('font-size', auto, 15px, auto);
}

.title {
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;

  @include pxToClampMobile('padding-top', auto, 14px, auto);
  @include pxToClampMobile('font-size', auto, 38px, auto);
}

.prize {
  width: 75%;
  margin: 0 auto;
  font-weight: 600;
  letter-spacing: -0.02em;
  letter-spacing: 3%;
  line-height: 1.2;
  text-transform: uppercase;


  @include pxToClampMobile('padding-top', auto, 18px, auto);
  @include pxToClampMobile('font-size', auto, 22px, auto);
}

.collect {
  width: 75%;
  margin: 0 auto;
  font-weight: 400;
  letter-spacing: -0.02em;
  letter-spacing: 3%;
  margin-bottom: 40px;
  line-height: 1.4;

  @include pxToClampMobile('padding-top', auto, 18px, auto);
  @include pxToClampMobile('font-size', auto, 14px, auto);
}

.disclaimer {
  width: 55%;
  margin: 0 auto;
  font-weight: 400;
  letter-spacing: -0.02em;
  letter-spacing: 3%;
  line-height: 1.4;

  @include pxToClampMobile('padding-top', auto, 18px, auto);
  @include pxToClampMobile('font-size', auto, 12px, auto);
}

