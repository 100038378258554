@import '../../../css/global';

.wrap {
  width: percentage(calc(320 / 375));
  max-width: 320px;
  margin: 0 auto;
  text-transform: uppercase;
}

.title {
  line-height: 1;
  font-weight: 900;
  color: #fff;

  @include pxToClampMobile('font-size', auto, 38px, auto);
}

.subtitle {
  font-weight: 600;
  color: #fff;
  letter-spacing: 3%;;
  line-height: 1.3;


  @include pxToClampMobile('padding-top', auto, 16px, auto);
  @include pxToClampMobile('font-size', auto, 22px, auto);
}

.body {
  font-weight: 600;

  margin-bottom: 100px;
  letter-spacing: 3%;;
  line-height: 1.3;
  @include pxToClampMobile('padding-top', auto, 34px, auto);
  @include pxToClampMobile('font-size', auto, 15px, auto);
}
