@import '../../../css/global';

.wrap {
  // padding: 0 0 80px; 
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.brandWrap {
  height: 100%;
  // @include pxToClampMobile('margin-left', auto, 70px, auto);
}

.brand {
  margin: 0 auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @include pxToClampMobile('width', auto, 165px * 0.9, auto);
  @include pxToClampMobile('height', auto, 100px * 0.9, auto);

  @include hidefont;
}

.prefix {
  margin: 10px auto 0;
  @include pxToClampMobile('font-size', auto, 20px, auto);
  color: #fff;
  letter-spacing: 120;
  line-height: 1.4;
  width: 55%;

  p {
    margin: 0;
  }
}

.contact {
  margin: 20px auto 0;
  @include pxToClampMobile('font-size', auto, 16px, auto);
  color: #fff;
  letter-spacing: 120;
  line-height: 1.4;
  width: 60%;
  h3 {
    color: #999999;
    margin-bottom: 5px;
  }

  p {
    margin: 0;
  }

  a {
    margin: 0;
  }
}

.title {
  margin: 20px auto 50px;

  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  color: #fff;
  width: 80%;

  @include pxToClampMobile('font-size', auto, 60px, auto);
}
